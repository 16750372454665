import React from 'react';
import './App.css';
import moment from 'moment';
import 'moment/locale/uk';

moment.locale('uk');

function App() {
    const [today, setToday] = React.useState(moment());
    const [calendar, setCalendar] = React.useState([]);
    const [workLength, setWorkLength] = React.useState(localStorage.getItem('workLength') || 3);
    const [workStart, setWorkStart] = React.useState(localStorage.getItem('workStart') || 0);

    const firstWorkingDay = moment().startOf('year').year(2000).add(workStart, 'day');

    React.useEffect(() => {
        localStorage.setItem('workLength', workLength);
        localStorage.setItem('workStart', workStart);
    }, [workLength, workStart]);

    React.useEffect(() => {
        var newCalendar = []
        const startDay = today.clone().startOf('month').startOf('isoWeek');
        const endDay = today.clone().endOf('month').endOf('isoWeek');
        let date = startDay.clone().subtract(1, 'day');
        while (date.isBefore(endDay, 'day')) {
            newCalendar.push(Array(7).fill(0).map(() => date.add(1, 'day').clone()))
        }
        setCalendar(newCalendar);
    }, [today]);

    const todayStrRaw = today.format('MMMM YYYY');
    const todayStr = todayStrRaw[0].toUpperCase() + todayStrRaw.substr(1);

    return (
        <div className="app">
            <div className="nav">
                <a href="#" onClick={e => { e.preventDefault(); setToday(val => val.clone().subtract(1, 'month')) }}>Назад</a>
                <div className="current-month">{todayStr}</div>
                <a href="#" onClick={e => { e.preventDefault(); setToday(val => val.clone().add(1, 'month')) }}>Вперед</a>
            </div>
            <div className="calendar">
                {calendar.map(days => <CalendarRow today={today} firstWorkingDay={firstWorkingDay} days={days} workLength={workLength} key={days[0].toString()} />)}
            </div>
            <div className="controls">
                <div>
                    Тривалість:
                    <div>
                        <a href="#" onClick={e => { e.preventDefault(); setWorkLength(v => v - 1) }}>-</a>
                        {workLength}
                        <a href="#" onClick={e => { e.preventDefault(); setWorkLength(v => v + 1) }}>+</a>
                    </div>
                </div>
                <div>
                    Зсув:
                    <div>
                        <a href="#" onClick={e => { e.preventDefault(); setWorkStart(v => v - 1) }}>-</a>
                        {workStart}
                        <a href="#" onClick={e => { e.preventDefault(); setWorkStart(v => v + 1) }}>+</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

function CalendarRow({ today, firstWorkingDay, days, workLength }) {
    return (
        <div className="calendar-row">
            {days.map(day => {
                const diff = day.diff(firstWorkingDay, 'days');
                const isWork = (diff % (workLength * 2)) >= workLength;
                return (
                    <div key={day.toString()} className={"calendar-day " + (day.month() !== today.month() ? 'calendar-other-month' : '') + (isWork ? ' calendar-day-busy' : '')}>
                        {day.date()}
                    </div>
                );
            })}
        </div>
    );
}

export default App;
